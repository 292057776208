<template>
    <div class="container-fluid p-dash-cliente-master pb-2 pt-3" style="margin-top: 100px">
    <div class="col-12">
        <div class="row">
            <div class="col-auto align-self-center text-right pr-0">
                <a class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta" href="?app=chamados.php"><i class="fas fa-arrow-left"></i></a>
            </div>
            <div class="col-10 align-self-center">
                <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">Novo chamado</h3>
                <div class="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb m-0 p-0">
                            <li class="breadcrumb-item"><a href="?app=dashboard.php">dashboard / assistência técnica / novo chamado</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 pt-1 px-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-9 pt-3">
                    <div class="card card-bg-dash"> 
                        <div class="card-body">
                            <form class="px-2 pt-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show" role="alert">
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <strong>Aviso!</strong> Preencha abaixo os dados para abertura do chamado de assistência técnica. Após relatar o problema ocorrido, nosso sistema abrirá um chamado e enviará para o técnico responsável. Tempo médio de resposta é de até 48 horas úteis. Qualquer dúvida, estamos à disposição.
                                            </div>
                                        </div>
                                        <div class="col-5 col-md-4 col-lg-3">
                                            <div class="form-group">
                                                <label>Código </label>
                                                <input type="text" class="form-control" v-model="report.id" disabled>
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label>Não conformidades</label>
                                                <b-form-select 
                                                    v-model="report.conformity_id"
                                                    :options="conformities"
                                                    v-validate="'required'"
                                                    name="not_conformity"
                                                    :class="{'is-invalid': errors.has('not_conformity')}">
                                                </b-form-select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label>Procedentes</label>
                                                <b-form-select v-model="report.precedent_id" :options="precedents" v-validate="'required'" name="precedent" :class="{'is-invalid': errors.has('precedent')}"></b-form-select>

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label>Assunto </label>
                                                <input type="text" id="inputSuccess1" class="form-control" placeholder="Identificação do chamado" v-model="report.subject" v-validate="'required'"
                                                name="subject"
                                                :class="{'is-invalid': errors.has('subject')}">
                                            </div>
                                        </div>
                                        <div class="col-12 pb-2">
                                            <label>Imóvel </label>
                                            <BaseEnterpriseBox v-model="report.enterprise_id" />
                                        </div>
                                        <div class="col-12 pt-3"></div>
                                        <div class="col-12 pb-2">
                                            <BaseAptClientBox v-model="report.floors" :enterprise_id="report.enterprise_id" :edit="false" :disabled="false" :client="true"/>

                                        </div>
                                        <div class="col-12">
                                            <BaseClientBox v-model="report.client_id" />
                                        </div>
                                        <div class="col-12 pt-3"></div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <label>Condomínio </label>
                                            <b-form-select v-model="report.conds" :options="cond" v-validate="'required'" name="cond" :class="{'is-invalid': errors.has('cond')}"></b-form-select>
                                        </div>
                                         <div class="col-12 col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label>N˚ torre</label>
                                                <b-form-select v-model="report.tower" v-validate="'required'" name="tower" :class="{'is-invalid': errors.has('tower')}" :options="towers"></b-form-select>

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label>Relatar problema </label>
                                                <textarea class="form-control" rows="6" placeholder="Mensagem" 
                                                v-model="report.description" v-validate="'required'"
                                                name="description"
                                                :class="{'is-invalid': errors.has('description')}"></textarea>
                                                <small id="textHelp" class="form-text text-muted">Informe detalhes sobre o chamado.</small>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12">
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccess"
                                                @before-remove="beforeRemove"
                                                @edit-image="editImage"
                                                :data-images="report.images"
                                                idUpload="myIdUpload"
                                                editUpload="myIdEdit"
                                                dragText="120px por 120px"
                                                browseText="Procurar imagens"
                                                :showPrimary="false"
                                                :showEdit="false"
                                            ></vue-upload-multiple-image>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-danger btn-relatar-problema">Relatar problema <i class="fas fa-angle-right font-18 align-middle ml-1"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-3 px-0 pt-tudoemumsolugar">
                    <Detail :report="report" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import ClientService from "@/services/resources/ClientService";
import EnterpriseService from "@/services/resources/EnterpriseService";
import EnterpriseConfigService from "@/services/resources/EnterpriseConfigService";
import ConfigService from "@/services/resources/ConfigService";
import TechnicalService from "@/services/resources/TechnicalService";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Detail from '@/components/Reports/Detail';
import moment from 'moment';

const serviceReport = ReportService.build();
const serviceClient = ClientService.build();
const serviceEnterprise = EnterpriseService.build();
const serviceEnterpriseConfig = EnterpriseConfigService.build();
const serviceConfig = ConfigService.build();
const serviceTechnical = TechnicalService.build();

export default {
    components:{
        VueUploadMultipleImage,
        Detail
    },
    data(){
        return{
            report:{
                open_like: 'CLIENT',
                client_id: null,
                conds: null,
                enterprise_id: null,
                subject: null,
                tower: null,
                status: 'PENDING',
                conformity_id: null,
                precedent_id: null,
                technical_id: null,
                description: null,
                date: null,
                floors: [],
                images: []
            },
            clients: [
                { value: null, text: 'Selecione' }
            ],
            enterprises: [
                { value: null, text: 'Selecione' }
            ],
            conformities: [
                { value: null, text: 'Selecione' }
            ],
            precedents: [
                { value: null, text: 'Selecione' }
            ],
            technical: [
                { value: null, text: 'Selecione' }
            ],
            cond: [
                { value: null, text: 'Selecione' }  
            ],
            towers: [
                { value: null, text: 'Selecione' }  
            ],
            floors: [],
            id: null
        }
    },
    watch:{
        "report.enterprise_id"(){
            this.selectEnterprise();
        }
    },
    methods:{
        uploadImageSuccess(formData, index, fileList) {
            this.report.images = fileList;
        },
        beforeRemove(index, done, fileList) {
            var r = confirm("Remover imagem?");
            if (r == true) {
                this.report.images.splice(index, 1);
                done();
            }
        },
        editImage(formData, index, fileList) {
            this.report.images = fileList;
        },
        generateGrounds(grounds){
            let total = 0;
            for(let i = 0; i < grounds.length; i++){
                total += grounds[i].apt_numbers * grounds[i].floors;
            }

            return total;
        },
        async fetchConfig(type){
            let data = {
            type: type
            }
            return serviceConfig.search(data).then();
        },
        async fetchClient(){
            return serviceClient.search().then();
        },
        async fetchEnterpriseConfig(type){
            let data = {
            type: type
            }
            return serviceEnterpriseConfig.search(data).then();
        },
        async selectEnterprise(){
            let enterprise = await serviceEnterprise.read({id: this.report.enterprise_id}).then();
            this.towers = [
                { value: null, text: 'Selecione' }  
            ];
            this.towers = this.towers.concat(new Array(enterprise.tower).fill().map((e,i) => {
                return {value: i+1, text: i+1}
            }));

        },
        save(){
            this.$validator.validateAll().then((result) => {
                if (result) {

                    
                let report = Object.assign(this.report);
                report.date = moment().format("YYYY-MM-DD H:mm");
                report.conds = [report.conds];

                if(this.id){
                    report.id = this.id;
                    serviceReport
                    .update(report)
                    .then(resp => {
                    this.$bvToast.toast('Seu chamado foi atualizado com sucesso!', {
                        title: 'Chamado atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                    console.log(err)
                    })
                        
                }else{
                    serviceReport
                    .create(report)
                    .then(resp => {
                    this.$bvToast.toast('Seu chamado foi criado com sucesso!', {
                        title: 'Chamado criado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                    console.log(err)
                    })
                }
                
                }
            });
        },
        
    },
    async mounted() {

        var resp = await this.fetchConfig('NOT_CONFORMITY');
        this.conformities = this.conformities.concat(resp.map(elem => {
            return {
                value: elem.id,
                text: elem.name
            }
        }))


        var resp = await this.fetchConfig('PRECEDENTS');
        this.precedents = this.precedents.concat(resp.map(elem => {
            return {
                value: elem.id,
                text: elem.name
            }
        }))


        var resp = await this.fetchEnterpriseConfig('COND');
        this.cond = this.cond.concat(resp.map(elem => {
            return {
                value: elem.id,
                text: elem.name
            }
        }))

    }
}
</script>